import React from 'react'

const Data = [
	{
		image: '/images/brand/ekiti-state.png',
	},
	{
		image: '/images/brand/EKSIR.png',
	},
	{
		image: '/images/brand/eksu-logo.png',
	},
	{
		image: '/images/brand/firs.png',
	},
	{
		image: '/images/brand/ondo-state.png',
	},
	{
		image: '/images/brand/rufus-giwa.png',
	},
	{
		image: '/images/brand/brand-7.png',
	},
	{
		image: '/images/brand/brand-8.png',
	},
]

const BrandItem = () => {
	return (
		<>
			{Data.map((data, index) => (
				<div className="col-lg-3 col-6" key={index}>
					<div className="brand-grid">
						<img src={process.env.PUBLIC_URL + data.image} alt="Brand" />
					</div>
				</div>
			))}
		</>
	)
}

export default BrandItem
